import { Component, Input, OnInit } from '@angular/core';

import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { filter, finalize, from, Observable } from 'rxjs';

import { RecipeDetailsBaseModalComponent } from '../recipe-details-base-modal/recipe-details-base-modal.component';
import { RecipeDetailsTabModel } from '../../models/recipe-details-tab/recipe-details-tab.model';
import { CorrectionProcessTimelineEventMixtureModel } from '../../../correction-processes/models/correction-process-timeline-event-mixture/correction-process-timeline-event-mixture.model';
import { CorrectionProcessTimelineEventMixtureRecipeTypeEnum } from '../../../correction-processes/enums/correction-process-timeline-event-mixture-recipe-type/correction-process-timeline-event-mixture-recipe-type.enum';
import { RecipeDetailsTabsEnum } from '../../enums/recipe-details-tabs/recipe-details-tabs.enum';
import { RecipePriceFormModel } from '../../../recipes/models/recipe-price-form/recipe-price-form.model';
import { RecipeDetailsModalParamsModel } from '../../models/recipe-details-modal-params/recipe-details-modal-params.model';
import { RecipeTypeModel } from '../../models/recipe-type/recipe-type.model';
import { RecipeTypeEnum } from '../../../recipes/enums/recipe-type/recipe-type.enum';
import { PrepareMixtureStateModel } from '../../../mixes/models/prepare-mixture-state/prepare-mixture-state.model';
import { CanDeactivateGuardModel } from '../../../shared/models/can-deactivate-guard/can-deactivate-guard.model';
import { LocationGetStateUtil } from '../../../shared/utils/location-get-state/location-get-state.util';
import { SelectMixtureTypeModalComponent } from '../../../correction-processes/components/select-mixture-type-modal/select-mixture-type-modal.component';
import { ModalSizeEnum } from '../../../modal/enums/modal-size.enum';

@Component({
  selector: 'app-recipe-details-modal[activeMixture][mixtureToCompare][measurementId][correctionProcessId]',
  templateUrl: './recipe-details-correction-process-modal.component.html',
  styleUrls: ['./recipe-details-correction-process-modal.component.scss'],
  standalone: false,
})
export class RecipeDetailsCorrectionProcessModalComponent extends RecipeDetailsBaseModalComponent implements OnInit {
  @Input() public activeMixture!: CorrectionProcessTimelineEventMixtureModel;
  @Input() public mixtureToCompare!: CorrectionProcessTimelineEventMixtureModel | null;
  @Input() public correctionProcessId!: string;
  @Input() public canWeightAndAddAsNewEventToTimeline: boolean = false;

  public novolOrWeightedMixture!: CorrectionProcessTimelineEventMixtureModel;
  public correctionMixture: CorrectionProcessTimelineEventMixtureModel | null = null;
  public currentSelectedMixture!: CorrectionProcessTimelineEventMixtureModel;
  public isDataForPrepareMixtureStateLoading: boolean = false;

  public override ngOnInit(): void {
    super.ngOnInit();

    this.initMixturesData();
    this.initToggleBox();
    this.initActiveOptionForToggleBox();
    this.initActiveMixtureData();
    this.initTabs();
    this.listenToActiveMixtureChange();
  }

  public override initTabs(): void {
    const recipeDetailsModalParams: RecipeDetailsModalParamsModel = {
      colorId: this.currentSelectedMixture.colorId,
      formulaId: this.currentSelectedMixture.formulaId,
      recipeType: this.currentSelectedMixture.type,
      id: this.currentSelectedMixture.recipeSignature,
      correctionName: this.currentSelectedMixture.typeName,
      isSpectralChartTabHidden: !this.mixtureToCompare,
    };

    this.recipeDetailsTabsService.initTabs(recipeDetailsModalParams, this.measurementId);

    if (!this.selectedTab) {
      return;
    }

    if (this.selectedTab.id !== RecipeDetailsTabsEnum.info) {
      this.initRecipeDetailsInfo();
    }

    this.updateIsShownRecipeTypeToggle(this.selectedTab);
    this.updateToggleBoxByActiveTab(this.selectedTab);
    this.loadTabData(this.selectedTab);
  }

  public override loadTabData(tab: RecipeDetailsTabModel | null): void {
    if (!tab) {
      return;
    }

    this.isContentLoading = true;

    this.loadTabDataStrategy(tab);
  }

  public override updateIsShownRecipeTypeToggle(tab: RecipeDetailsTabModel): void {
    const isNotIngredientsTab: boolean = tab.id !== RecipeDetailsTabsEnum.ingredients;
    const isNotPreviewTab: boolean = tab.id !== RecipeDetailsTabsEnum.preview;

    this.isShownRecipeTypeToggle = isNotIngredientsTab && isNotPreviewTab && !!(this.activeMixture && this.mixtureToCompare);
  }

  public override recipePriceFormValueChange(recipePriceForm: RecipePriceFormModel): void {
    this.recipePriceForm = recipePriceForm;
    this.isContentLoading = true;

    this.initRecipeDetailsPrices();
  }

  public override updateFormulaSpectralChart(): void {
    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaSpectralChartData(
        this.currentSelectedMixture.type,
        this.colorId,
        this.formulaId,
        this.measurementId,
        this.spectralChartGeometriesIds
      )
      .subscribe();
  }

  public override updateFormulaAngles(): void {
    const canShowCompareToCorrection: boolean = !!this.correctionMixture;

    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaAnglesData(this.colorId, this.formulaId, this.measurementId, this.anglesGeometriesIds, canShowCompareToCorrection)
      .subscribe();
  }

  public override goToPrepareMixturePage(): void {
    if (this.correctionMixture && this.novolOrWeightedMixture) {
      this.openModalToSelectMixtureType(this.correctionMixture, this.novolOrWeightedMixture);

      return;
    }

    if (!this.canWeightAndAddAsNewEventToTimeline) {
      this.openConfirmationWeighingBesidesTimelinePopup(this.activeMixture);

      return;
    }

    this.navigateToPrepareMixturePage(this.activeMixture);
  }

  protected override loadTabDataStrategy(tab: RecipeDetailsTabModel): void {
    switch (tab.id) {
      case RecipeDetailsTabsEnum.info:
        this.initRecipeDetailsInfo();
        break;
      case RecipeDetailsTabsEnum.prices:
        this.initRecipeDetailsPrices();
        break;
      case RecipeDetailsTabsEnum.ingredients:
        this.initMixtureIngredients();
        break;
      case RecipeDetailsTabsEnum.chart:
        this.initRecipeDetailsSpectralChart();
        break;
      case RecipeDetailsTabsEnum.preview:
        this.initRecipeDetailsPreview();
        break;
      default:
        this.initRecipeDetailsInfo();
        break;
    }
  }

  protected override initRecipeDetailsInfo(): void {
    if (!this.colorId || !this.formulaId) {
      return;
    }

    const mixtureId: string | undefined = this.getMixtureIdForCurrentSelectedMixture();

    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaDetails(this.colorId, this.formulaId, this.currentSelectedMixture.type, this.measurementId, mixtureId)
      .pipe(
        finalize(() => {
          this.isContentLoading = false;
        })
      )
      .subscribe();
  }

  protected override initRecipeDetailsPrices(): void {
    if (!this.formulaId) {
      return;
    }

    const mixtureId: string | undefined = this.getMixtureIdForCurrentSelectedMixture();

    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaPrices(
        this.formulaId,
        this.recipePriceForm,
        this.currentSelectedMixture.type,
        this.measurementId,
        this.currentSelectedMixture.recipeSignature,
        mixtureId
      )
      .pipe(
        finalize(() => {
          this.isContentLoading = false;
        })
      )
      .subscribe();
  }

  protected override refreshRecipeFormulaIngredients(): void {
    this.initMixtureIngredients();
  }

  protected override initRecipeDetailsPreview(): void {
    if (!this.colorId || !this.formulaId) {
      return;
    }

    this.isContentLoading = true;

    const canShowCompareToCorrection: boolean = !!this.correctionMixture;

    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaAnglesData(this.colorId, this.formulaId, this.measurementId, this.anglesGeometriesIds, canShowCompareToCorrection)
      .pipe(
        finalize(() => {
          this.isContentLoading = false;
        })
      )
      .subscribe();
  }

  protected override initRecipeDetailsSpectralChart(): void {
    if (!this.colorId || !this.formulaId) {
      return;
    }

    this.subscription = this.recipeDetailsModalService
      .initRecipeFormulaSpectralChartData(
        this.recipeTypeForm.value,
        this.colorId,
        this.formulaId,
        this.measurementId,
        this.spectralChartGeometriesIds
      )
      .pipe(
        finalize(() => {
          this.isContentLoading = false;
        })
      )
      .subscribe();
  }

  protected override listenToTabChanges(): void {
    this.subscription = this.recipeDetailsTabsService.activeTab$.subscribe((tab: RecipeDetailsTabModel) => {
      if (!tab) {
        return;
      }

      this.selectedTab = tab;
      this.updateIsShownRecipeTypeToggle(tab);
      this.updateToggleBoxByActiveTab(tab);
      this.loadTabData(tab);
    });
  }

  private initMixturesData(): void {
    this.initNovolOrWeightedMixture();
    this.initCorrectionMixture();
    this.initCurrentSelectedMixture();
  }

  private initNovolOrWeightedMixture(): void {
    if (
      this.activeMixture.type === CorrectionProcessTimelineEventMixtureRecipeTypeEnum.novolRecipe ||
      this.activeMixture.type === CorrectionProcessTimelineEventMixtureRecipeTypeEnum.weightedRecipe
    ) {
      this.novolOrWeightedMixture = this.activeMixture;
    } else if (
      this.mixtureToCompare &&
      (this.mixtureToCompare.type === CorrectionProcessTimelineEventMixtureRecipeTypeEnum.novolRecipe ||
        this.mixtureToCompare.type === CorrectionProcessTimelineEventMixtureRecipeTypeEnum.weightedRecipe)
    ) {
      this.novolOrWeightedMixture = this.mixtureToCompare;
    }
  }

  private initCorrectionMixture(): void {
    if (this.activeMixture.type === CorrectionProcessTimelineEventMixtureRecipeTypeEnum.correction) {
      this.correctionMixture = this.activeMixture;
    } else if (this.mixtureToCompare && this.mixtureToCompare.type === CorrectionProcessTimelineEventMixtureRecipeTypeEnum.correction) {
      this.correctionMixture = this.mixtureToCompare;
    }
  }

  private initCurrentSelectedMixture(): void {
    this.currentSelectedMixture = this.activeMixture;
  }

  private initToggleBox(): void {
    this.recipeTypeOptions = [
      {
        label: this.novolOrWeightedMixture.typeName,
        value: this.novolOrWeightedMixture.type,
        isDisabled: false,
      },
    ];

    if (this.correctionMixture) {
      this.recipeTypeOptions.push({
        label: this.correctionMixture.typeName,
        value: this.correctionMixture.type,
        isDisabled: false,
      });
    }
  }

  private initActiveMixtureData(): void {
    this.initActiveMixtureColorId();
    this.initActiveMixtureFormulaId();
    this.initSelectedRecipeData();
  }

  private initActiveMixtureColorId(): void {
    this.colorId = this.activeMixture.colorId;
  }

  private initActiveMixtureFormulaId(): void {
    this.formulaId = this.activeMixture.formulaId;
  }

  private initActiveOptionForToggleBox(): void {
    this.recipeTypeForm.setValue(this.currentSelectedMixture.type);
  }

  private initSelectedRecipeData(): void {
    this.colorId = this.activeMixture.colorId;
    this.formulaId = this.activeMixture.formulaId;
  }

  private initMixtureIngredients(): void {
    if (!this.measurementId) {
      return;
    }

    const mixtureId: string | undefined = this.getMixtureIdForCurrentSelectedMixture();

    let $ingredients: Observable<void> = this.recipeDetailsModalService.initRecipeFormulaIngredients(
      this.formulaId,
      this.currentSelectedMixture.type,
      this.measurementId,
      this.currentSelectedMixture.recipeSignature,
      mixtureId
    );

    if (this.correctionMixture) {
      const mixtureId: string | undefined = this.getMixtureIdForNovolOrWeightedMixture();

      $ingredients = this.recipeDetailsModalService.initRecipeFormulaCorrectionIngredients(
        this.measurementId,
        this.currentSelectedMixture.recipeSignature,
        this.novolOrWeightedMixture.type,
        mixtureId
      );
    }

    this.isContentLoading = true;

    this.subscription = $ingredients
      .pipe(
        finalize(() => {
          this.isContentLoading = false;
        })
      )
      .subscribe();
  }

  private getMixtureIdForCurrentSelectedMixture(): string | undefined {
    return this.currentSelectedMixture.type === CorrectionProcessTimelineEventMixtureRecipeTypeEnum.weightedRecipe
      ? this.currentSelectedMixture.sourceId
      : undefined;
  }

  private getMixtureIdForNovolOrWeightedMixture(): string | undefined {
    return this.novolOrWeightedMixture.type === CorrectionProcessTimelineEventMixtureRecipeTypeEnum.weightedRecipe
      ? this.novolOrWeightedMixture.sourceId
      : undefined;
  }

  private setCurrentSelectedMixture(): void {
    if (this.recipeTypeForm.value === this.novolOrWeightedMixture.type) {
      this.currentSelectedMixture = this.novolOrWeightedMixture;
    } else if (this.correctionMixture && this.recipeTypeForm.value === this.correctionMixture.type) {
      this.currentSelectedMixture = this.correctionMixture;
    }
  }

  private updateToggleBoxByActiveTab(tab: RecipeDetailsTabModel): void {
    if (!this.novolOrWeightedMixture) {
      return;
    }

    const isWeightedMixtureAndCorrectionMixture: boolean =
      this.novolOrWeightedMixture.type === CorrectionProcessTimelineEventMixtureRecipeTypeEnum.weightedRecipe && !!this.correctionMixture;
    const isActiveSpectralChartTab: boolean = tab.id === RecipeDetailsTabsEnum.chart;

    if (isWeightedMixtureAndCorrectionMixture && isActiveSpectralChartTab) {
      this.recipeTypeOptions[0].isDisabled = true;
      this.recipeTypeForm.setValue(this.correctionMixture?.type as RecipeTypeModel, { emitEvent: false });

      return;
    }

    this.recipeTypeOptions[0].isDisabled = false;
  }

  private listenToActiveMixtureChange(): void {
    this.subscription = this.recipeTypeForm.valueChanges.subscribe({
      next: () => {
        this.setCurrentSelectedMixture();
        this.loadTabData(this.selectedTab);
      },
    });
  }

  private openModalToSelectMixtureType(
    correctionMixture: CorrectionProcessTimelineEventMixtureModel,
    novolOrWeightedMixture: CorrectionProcessTimelineEventMixtureModel
  ): void {
    const modalRef: NgbModalRef = this.modalService.openModal(SelectMixtureTypeModalComponent, ModalSizeEnum.small);
    const modalRefInstance: SelectMixtureTypeModalComponent = modalRef.componentInstance as SelectMixtureTypeModalComponent;

    modalRefInstance.correctionMixture = correctionMixture;
    modalRefInstance.novolOrWeightedMixture = novolOrWeightedMixture;
    modalRefInstance.canWeightAndAddAsNewEventToTimeline = this.canWeightAndAddAsNewEventToTimeline;

    this.subscription = from(modalRef.result)
      .pipe(filter((result: CorrectionProcessTimelineEventMixtureModel | undefined) => !!result))
      .subscribe({
        next: (mixture: CorrectionProcessTimelineEventMixtureModel) => {
          this.navigateToPrepareMixturePage(mixture);
        },
        error: () => {
          return;
        },
      });
  }

  private openConfirmationWeighingBesidesTimelinePopup(mixture: CorrectionProcessTimelineEventMixtureModel): void {
    this.subscription = this.modalService
      .openPopupModal(
        'recipeDetails.recipeDetailsCorrectionProcessModal.confirmationWeighingBesidesTimelinePopup.titleKey',
        'recipeDetails.recipeDetailsCorrectionProcessModal.confirmationWeighingBesidesTimelinePopup.textKey',
        'recipeDetails.recipeDetailsCorrectionProcessModal.confirmationWeighingBesidesTimelinePopup.successButtonLabelKey',
        'recipeDetails.recipeDetailsCorrectionProcessModal.confirmationWeighingBesidesTimelinePopup.cancelButtonLabelKey',
        false
      )
      .pipe(filter((result: boolean) => !!result))
      .subscribe({
        next: () => {
          this.navigateToPrepareMixturePage(mixture);
        },
      });
  }

  private navigateToPrepareMixturePage(mixture: CorrectionProcessTimelineEventMixtureModel): void {
    this.setIsDataForPrepareMixtureStateLoading(true);

    let prepareMixtureState$: Observable<PrepareMixtureStateModel>;

    if (mixture.type === RecipeTypeEnum.weightedRecipe) {
      prepareMixtureState$ = this.recipeDetailsModalService.getMixtureDetailsToPrepareMixture(mixture.sourceId, this.measurementId);
    } else {
      prepareMixtureState$ = this.recipeDetailsModalService.getRecipeDetailsToPrepareMixture(
        mixture.type,
        mixture.colorId,
        mixture.formulaId,
        mixture.recipeSignature,
        this.measurementId
      );
    }

    this.subscription = prepareMixtureState$
      .pipe(finalize(() => this.setIsDataForPrepareMixtureStateLoading(false)))
      .subscribe((prepareMixtureState: PrepareMixtureStateModel) => {
        const measurementId: string | undefined = this.canWeightAndAddAsNewEventToTimeline ? this.measurementId : undefined;
        prepareMixtureState.createMixture.source.measurementId = measurementId;

        const locationState: CanDeactivateGuardModel<PrepareMixtureStateModel> = {
          canDeactivate: true,
          data: {
            ...prepareMixtureState,
            ...(this.canWeightAndAddAsNewEventToTimeline && { correctionProcessId: this.correctionProcessId }),
          },
        };

        this.router.navigate(['/mixes/prepare-mixture'], { state: LocationGetStateUtil.createState(locationState) }).then(() => {
          this.modalService.dismissAll();
        });
      });
  }

  private setIsDataForPrepareMixtureStateLoading(isDataForPrepareMixtureStateLoading: boolean): void {
    this.isDataForPrepareMixtureStateLoading = isDataForPrepareMixtureStateLoading;
  }
}
