<div class="form-control-toggle-box form-control" [ngClass]="{ 'is-error': canShowErrors && control?.invalid }">
  <div class="options">
    <div
      class="option"
      *ngFor="let option of toggleBoxOptionsList"
      [ngClass]="{
        'is-selected': option === selectedOption,
        'is-disabled': option.isDisabled,
      }"
      (click)="toggleSelectedValue(option)"
    >
      <span class="label" [class.is-double-line]="option.label.includes('<br>')" [innerHTML]="option.label | translate"></span>
    </div>
  </div>

  <app-form-control-validation-errors
    class="errors-list"
    *ngIf="control?.errors as errors"
    [errors]="errors"
  ></app-form-control-validation-errors>
</div>
