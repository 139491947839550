import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

import { FormControlToggleBoxComponent } from '../../../forms/components/form-control-toggle-box/form-control-toggle-box.component';

@Component({
  selector: 'app-correction-process-recipe-type-toggle-box',
  templateUrl: './correction-process-recipe-type-toggle-box.component.html',
  standalone: false,
})
export class CorrectionProcessRecipeTypeToggleBoxComponent<ValueType>
  extends FormControlToggleBoxComponent<ValueType>
  implements OnInit, OnDestroy
{
  constructor(@Optional() ngControl: NgControl) {
    super(ngControl);
  }
}
